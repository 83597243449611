<template>
  <v-layout column justify-center fill-height class="photo-mission">
    <ResizableText
      v-if="isViewerAuditorLike"
      message="The users are uploading pictures"
      class="h-full w-full"
    />
    <template v-if="canPlay">
      <v-flex grow d-flex align-center>
        <image-uploader
          v-if="isScribe"
          :debug="1"
          :maxWidth="300"
          :quality="1"
          :autoRotate="true"
          outputFormat="verbose"
          :preview="true"
          :className="['fileinput', { 'fileinput--loaded': hasImage }]"
          capture="environment"
          accept=".jpg,.png,.jpeg"
          @input="setImage"
        >
          <label id="upload-image" for="fileInput" slot="upload-label">
            <figure>
              <svg-icon name="camera-solid" height="42" width="42" />
            </figure>
            <h3 class="upload-caption">
              {{ uploadImageLabel }}
            </h3>
          </label>
        </image-uploader>
      </v-flex>
      <TextField
        :placeholder="placeholder"
        :status="status"
        :show="showTextField"
        :buttonText="'SUBMIT'"
        @onSubmit="onSubmit"
        class="mission-text-feild"
      />
    </template>

    <v-layout
      class="mission-instructions relative"
      column
      v-else-if="!hasImage"
    >
      <v-flex grow d-flex>
        <ResizableText :message="currentMission.instructions" />
      </v-flex>

      <v-flex
        v-if="game.importedFromBreadcrumb && presentationMission"
        class="mission-instructions__presentation-image"
        :style="`background: url('${presentationMission}') center / contain no-repeat;`"
      >
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import { GameMixin } from "@/mixins"
import { uploadMedia, getBlobFromDataURL } from "@/services/storage.service"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import TextField from "./TextField.vue"
import uniqid from "uniqid"

export default {
  name: "PhotoMission",
  mixins: [GameMixin],
  components: {
    ResizableText,
    TextField
  },
  props: {
    mode: String
  },
  data() {
    return {
      waitingText: "Waiting on Scribe...",
      lastIncorrectPlays: 0,
      hasImage: false,
      loadingImage: false,
      imageUrl: ""
    }
  },
  computed: {
    ...mapGetters(["missionAnswers", "missionCompletedPlays"]),
    ...mapGetters("auth", ["token"]),
    isViewerAuditorLike() {
      return this.$store.getters["group/isViewerAuditorLike"]
    },
    game() {
      return this.$store.getters.game
    },
    isWin() {
      return this.gotItRight
    },
    // if not a win and all tries are wasted
    isFail() {
      return !this.isWin && this.answers.length === this.numOfTries
    },
    isCompleted() {
      return this.isFail || this.isWin
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    numOfTries() {
      var tmp = this.numOfTriesOriginal
      if (tmp == "Unlimited") tmp = 1000
      return parseInt(tmp)
    },
    correctAnswer() {
      return this.currentMission.answer
    },
    answers() {
      if (!this.missionPlays.length) return []
      // The admin gets 0 while a player gets their team ID
      const teamID = this.teamID || 0
      const plays = this.missionPlays.filter(play => play.teamID === teamID)
      if (plays.length) return plays[0].answer
      else return []
    },
    status() {
      return this.hasImage ? "active" : ""
    },
    lineThroughClass() {
      if (this.isFail || this.lastIncorrectPlays < this.incorrectPlays()) {
        this.lastIncorrectPlays = this.incorrectPlays()
        return " line-through"
      } else return ""
    },
    showTextField() {
      return this.mode !== "explain" ? "show" : ""
    },
    canPlay() {
      return (
        this.mode === "play" || this.mode === "huddle" || this.mode === "social"
      )
    },
    placeholder() {
      // No data entered for a non scribe
      if (!this.isScribe && !this.missionAnswers.length) return this.waitingText
      // Set the placeholder message for a non scrive
      // to the latest enetered by a scribe
      // On win
      if (!this.hasImage) return "Upload photo first"
      // If scrive and ready to go
      if (this.isScribe && this.canPlay) return "Enter caption here..."
      // Scribe but not ready to go
      return "Wait..."
    },
    isScribe() {
      return this.$store.getters.isScribe
    },
    isHost() {
      return this.user.role == "facilitator"
    },
    uploadImageLabel() {
      if (this.loadingImage) {
        return "Uploading photo..."
      } else if (this.hasImage || this.userAnswer) {
        return "Replace Image"
      }
      return "Upload a photo first"
    },
    userAnswer() {
      return this.missionCompletedPlays.find(
        answer => answer?.userID === this.user?.id
      )
    },
    presentationMission() {
      return this.currentMission?.presentationImage
    }
  },
  methods: {
    async setImage(e) {
      this.loadingImage = true
      try {
        const fileName = `gamephotos/${uniqid()}-${this.user.username}.png`
        const blob = await getBlobFromDataURL(e.dataUrl)
        this.imageUrl = await uploadMedia({
          fileName,
          blob,
          token: this.token
        })
        this.hasImage = true
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingImage = false
      }
    },
    async onSubmit(data) {
      if (!this.isScribe) return console.log("No rights for submit")
      if (!data) return console.log("Won't submit an empty string")
      this.caption = data
      await this.checkAnswer()
      this.caption = ""
      this.answer = ""
    }
  }
}
</script>

<style lang="scss">
.photo-mission {
  .img-preview {
    max-height: 150px;
    max-width: 200px;
  }
  .fileinput {
    display: none;
  }
  #upload-image {
    cursor: pointer;
  }
  .text-input-mission {
    .mission-instructions {
      color: #fff;
      user-select: none;
      padding: 25px 10px 5px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .mission-text-feild {
      position: relative;
      margin-left: 60px;
      margin-right: 6px;
      margin-bottom: 6px;
      transform: translateY(10px);
      visibility: hidden;
      opacity: 0;
      transition: all 0.25s ease-out;
    }
  }

  .mission-instructions {
    &__presentation-image {
      height: 30%;
    }
  }
}
</style>
