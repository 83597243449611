var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "photo-mission",
      attrs: { column: "", "justify-center": "", "fill-height": "" },
    },
    [
      _vm.isViewerAuditorLike
        ? _c("ResizableText", {
            staticClass: "h-full w-full",
            attrs: { message: "The users are uploading pictures" },
          })
        : _vm._e(),
      _vm.canPlay
        ? [
            _c(
              "v-flex",
              { attrs: { grow: "", "d-flex": "", "align-center": "" } },
              [
                _vm.isScribe
                  ? _c(
                      "image-uploader",
                      {
                        attrs: {
                          debug: 1,
                          maxWidth: 300,
                          quality: 1,
                          autoRotate: true,
                          outputFormat: "verbose",
                          preview: true,
                          className: [
                            "fileinput",
                            { "fileinput--loaded": _vm.hasImage },
                          ],
                          capture: "environment",
                          accept: ".jpg,.png,.jpeg",
                        },
                        on: { input: _vm.setImage },
                      },
                      [
                        _c(
                          "label",
                          {
                            attrs: {
                              slot: "upload-label",
                              id: "upload-image",
                              for: "fileInput",
                            },
                            slot: "upload-label",
                          },
                          [
                            _c(
                              "figure",
                              [
                                _c("svg-icon", {
                                  attrs: {
                                    name: "camera-solid",
                                    height: "42",
                                    width: "42",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("h3", { staticClass: "upload-caption" }, [
                              _vm._v(" " + _vm._s(_vm.uploadImageLabel) + " "),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("TextField", {
              staticClass: "mission-text-feild",
              attrs: {
                placeholder: _vm.placeholder,
                status: _vm.status,
                show: _vm.showTextField,
                buttonText: "SUBMIT",
              },
              on: { onSubmit: _vm.onSubmit },
            }),
          ]
        : !_vm.hasImage
        ? _c(
            "v-layout",
            {
              staticClass: "mission-instructions relative",
              attrs: { column: "" },
            },
            [
              _c(
                "v-flex",
                { attrs: { grow: "", "d-flex": "" } },
                [
                  _c("ResizableText", {
                    attrs: { message: _vm.currentMission.instructions },
                  }),
                ],
                1
              ),
              _vm.game.importedFromBreadcrumb && _vm.presentationMission
                ? _c("v-flex", {
                    staticClass: "mission-instructions__presentation-image",
                    style:
                      "background: url('" +
                      _vm.presentationMission +
                      "') center / contain no-repeat;",
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }